<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'cokies',
  data: () => ({
    drop1: false,
    drop2: false,
    drop3: false,
    drop4: false,

    targetCookies: true,
    functionalCookies: true,
    operationalCookies: true,
  }),
  computed: {
    ...mapGetters(["getCokiesPopup", "getCokiesPopupStage2"])
  },
  methods: {

    ...mapActions(['doCloseCokiesPopup', 'doCloseCokiesPopupStage2', 'doOpenCokiesPopupStage2']),
    // start banner nav
    settingCookies() {
      document.querySelector("#onetrust-pc-sdk").classList.remove("ot-hide");
      console.log(document.querySelector('.onetrust-pc-dark-filter'));
      document.querySelector('.onetrust-pc-dark-filter').style.removeProperty('z-index');
    },
    hideSetting() {
      this.doCloseCokiesPopupStage2()
    },
    hideBanner() {
      this.hideSetting();
      this.doCloseCokiesPopup()

      localStorage.setItem('cookies', this.getCokiesPopup);

    },
    tabChange(event) {

      document
          .querySelectorAll(".category-menu-switch-handler")
          .forEach((el) => {
            if (el.classList.contains("ot-active-menu")) {
              el.classList.remove("ot-active-menu");
            }
          });

      document.querySelectorAll(".ot-desc-cntr").forEach((el) => {
        // console.log(el);

        if (!el.classList.contains("ot-hide")) {
          el.classList.add("ot-hide");
        }
      });


      var path = event.path || (event.composedPath && event.composedPath());
      path.forEach((el) => {
        if (el.classList) {
          if (el.classList.contains("ot-cat-item")) {
            document
                .querySelector(
                    `#ot-desc-id-${el.getAttribute("data-optanongroupid")}`
                )
                .classList.remove("ot-hide");

            el.querySelector(".category-menu-switch-handler").classList.add(
                "ot-active-menu"
            );
          }
        }
      });


    },
    // end banner nav
    setCookie(name, value, options = {}) {
      options = {
        path: "/",
        // при необходимости добавьте другие значения по умолчанию
        ...options,
      };

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
      }

      let updatedCookie =
          encodeURIComponent(name) + "=" + encodeURIComponent(value);

      for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
          updatedCookie += "=" + optionValue;
        }
      }

      document.cookie = updatedCookie;
    },
    getCookie(name) {
      let matches = document.cookie.match(
          new RegExp(
              "(?:^|; )" +
              name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
              "=([^;]*)"
          )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    deleteCookie(name) {
      this.setCookie(name, "", {
        "max-age": -1,
      });
    },
    cookiesDelete() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    },

    acceptAll() {
      this.hideSetting();

      this.targetCookies = true;
      this.functionalCookies = true;
      this.operationalCookies = true;

      this.hideBanner();
      this.acceptCookies();
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1)

    },
    rejectAll() {
      this.hideSetting();

      this.targetCookies = false;
      this.functionalCookies = false;
      this.operationalCookies = false;

      this.hideBanner();
      this.acceptCookies();
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1)


    },
    accept() {
      this.hideBanner();
      this.acceptCookies();

      // setTimeout(() => {
      //   window.location.reload()
      // }, 1)

    },
    acceptCookies() {
      this.cookiesDelete();


      let expires = new Date(new Date().valueOf() + 30000 * 60 * 60 * 24 * 15);
      document.cookie = "pca-targetCookies=" + this.targetCookies + "; expires=" + expires;
      document.cookie = "pca-functionalCookies=" + this.functionalCookies + "; expires=" + expires;
      document.cookie = "pca-operationalCookies=" + this.operationalCookies + "; expires=" + expires;

      if (!this.getCokiesPopup) {
        let expires = new Date(new Date().valueOf() + 30000 * 60 * 60 * 24 * 15);
        document.cookie = "pca-scb=false; expires=" + expires;
      }

      if (this.targetCookies) {
        // FB
        // var meta = document.createElement('meta');
        // meta.name = "facebook-domain-verification";
        // meta.content = "wwo9x0l8twqj0u1scmmsm7muxigaig";
        // document.getElementsByTagName('head')[0].appendChild(meta);

        console.log("targetCookies");
      } else {
        // let fb = document.querySelector('meta[name="facebook-domain-verification"]');
        // if (fb) {
        //   document.querySelector('meta[name="facebook-domain-verification"]').remove();
        //   console.log("fb removed");
        // }

      }

      if (this.functionalCookies) {
        // -
        console.log("functionalCookies");
      }

      if (this.operationalCookies) {
        console.log("operationalCookies");
        // аналитике Гугла, Тег менеджера Гугла и Хотжар.

        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3728891,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

        (function () {
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-LHDQCX00P9');
        })();




      } else {
        // delete window.dataLayer;
        // window.gtag = function(){
        //   // stub function
        // }
        const cyrb53 = function (str, seed = 0) {
          let h1 = 0xdeadbeef ^ seed,
              h2 = 0x41c6ce57 ^ seed;
          for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
          }
          h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
          h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
          return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        };

        let clientIP = fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(({ip}) => {
              return ip;
            });
        ;
        let validityInterval = Math.round(new Date() / 1000 / 3600 / 24 / 4);
        let clientIDSource = clientIP + ";" + window.location.host + ";" + navigator.userAgent + ";" + navigator.language + ";" + validityInterval;
        let clientIDHashed = cyrb53(clientIDSource).toString(16);

        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3728891,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-LHDQCX00P9');

      }
    }
  },
  mounted() {
    if (this.getCokiesPopup) {
      this.getCokiesPopup = this.getCookie("pca-scb") !== "false" ? true : true;

      this.targetCookies = this.getCookie('pca-targetCookies') === "true" ? true : true;
      this.functionalCookies = this.getCookie('pca-functionalCookies') === "true" ? true : true;
      this.operationalCookies = this.getCookie('pca-operationalCookies') === "true" ? true : true;

      this.acceptCookies();
    }
  },
}
</script>

<template>
  <div class="cokies">
    <div class="stage1">
      <div class="double-block">

        <div class="text">
          <h5>Наш сайт використовує файли cookies</h5>
          <p>Ми б хотіли використовувати додаткові (опціональні) реп'яшки (файли cookie) для кращого розуміння
            використання Вами цього веб-сайту, та щоб ділитися інформацією з нашими рекламними партнерами. Докладніше
            про
            використання обов’язкових файлів cookie, які необхідні для перегляду цього веб-сайту, та про додаткові
            (опціональні) файли cookie, які можна відхилити або прийняти за допомогою кнопок нижче, а також про те як
            можна управляти Вашою згодою або відкликати її у будь-який час, можна дізнатися у нашому
            <router-link to="/policy">Положенні про конфіденційність</router-link>
          </p>
        </div>

        <div class="btn-wrapper">
          <div class="btn color" @click="acceptAll">Прийняти файли Cookies</div>
          <div @click="doOpenCokiesPopupStage2()" class="btn base">Налаштування файлів Cookies</div>
          <div class="btn base" @click="rejectAll">Відхилити файли Cookies</div>
        </div>
      </div>
    </div>

    <div v-if="getCokiesPopupStage2" class="stage2">
      <div class="block">
        <div @click="doCloseCokiesPopupStage2()" class="close">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#2FDAFF"/>
          </svg>
        </div>

        <h5>Центр настройки конфіденційності</h5>
        <p class="reg-text">Коли ви відвідуєте будь-який веб-сайт, він може зберігати або отримувати інформацію з
          вашого
          браузера, в
          основному у формі файлів cookie. Ця інформація може стосуватися вас, ваших уподобань, вашого пристрою або
          використовуватися для того, щоб сайт працював так, як ви очікуєте. Інформація зазвичай не ідентифікує вас
          безпосередньо, але може дати вам більш персоналізований веб-досвід. Ви можете не дозволяти використання
          деяких
          типів файлів cookie. Натисніть на заголовки різних категорій, щоб дізнатися більше і змінити настройки за
          замовчуванням. Тим не менш, ви повинні знати, що блокування деяких типів файлів cookie може вплинути на вашу
          роботу з сайтом і послугами, які ми можемо запропонувати.</p>
        <router-link to="/policy">Положення про конфіденційність</router-link>

        <div class="control">
          <h4>Керування налаштуваннями cookie</h4>
          <div class="item">
            <div class="visible">
              <div @click="drop1 = !drop1" class="name">
                <svg v-if="!drop1" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#2FDAFF"/>
                </svg>

                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 13H5.5V11H19.5V13Z" fill="#2FDAFF"/>
                </svg>

                <p>Строго необхідні cookies</p>
              </div>
              <div class="input">
                <p>Завжди активні</p>
              </div>
            </div>
            <div v-if="drop1" class="hide">
              <p>Ці файли cookie необхідні для функціонування веб-сайту і не можуть бути відключені в наших системах.
                Зазвичай вони встановлюються тільки у відповідь на ваші дії, що призводять до запиту на послуги, такі
                як
                установка налаштувань конфіденційності, вхід в систему або заповнення форм. Ви можете налаштувати свій
                браузер так, щоб він блокував або попереджав вас про ці файли cookie, але деякі частини сайту можуть
                не
                працювати.</p>
            </div>
          </div>
          <div class="item">
            <div class="visible">
              <div @click="drop2 = !drop2" class="name">
                <svg v-if="!drop2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#2FDAFF"/>
                </svg>

                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 13H5.5V11H19.5V13Z" fill="#2FDAFF"/>
                </svg>

                <p>Експлуатаційні cookies</p>
              </div>
              <div class="input">
                <input
                    type="checkbox"
                    name="ot-group-id-C0002"
                    id="ot-group-id-C0002"
                    aria-checked="false"
                    role="switch"
                    class="category-switch-handler"
                    data-optanongroupid="C0002"
                    aria-labelledby="ot-header-id-C0002"
                    v-model="operationalCookies"
                />
                <label class="ot-switch" for="ot-group-id-C0002">
                  <span class="ot-switch-nob"></span>
                </label>
              </div>
            </div>
            <div v-if="drop2" class="hide">
              <p>Ці файли Cookie дають змогу аналізувати взаємодію з користувачами та використовЦі файли cookie
                дозволяють нам розраховувати відвідування і джерела трафіку, щоб ми могли вимірювати і покращувати
                продуктивність нашого сайту. Вони допомагають нам дізнатися, які сторінки є найбільш і найменш
                популярними, та побачити, як відвідувачі переміщуються по сайту. Вся інформація, яку збирають ці файли
                cookie, є сукупною і тому анонімною. Якщо ви не дозволите використання цих файлів cookie, ми не
                дізнаємося, коли ви відвідали наш сайт.уються для керування веб-сайтом і включають фіксацію кількості
                відвідувачів та іншу веб-аналітику.</p>
            </div>
          </div>
          <div class="item">
            <div class="visible">
              <div @click="drop3 = !drop3" class="name">
                <svg v-if="!drop3" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#2FDAFF"/>
                </svg>

                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 13H5.5V11H19.5V13Z" fill="#2FDAFF"/>
                </svg>

                <p>Функціональні cookies</p>
              </div>
              <div class="input">
                <input
                    type="checkbox"
                    name="ot-group-id-C0003"
                    id="ot-group-id-C0003"
                    aria-checked="false"
                    role="switch"
                    class="category-switch-handler"
                    data-optanongroupid="C0003"
                    aria-labelledby="ot-header-id-C0003"
                    v-model="functionalCookies"
                />
                <label class="ot-switch" for="ot-group-id-C0003">
                  <span class="ot-switch-nob"></span>
                </label>
              </div>
            </div>
            <div v-if="drop3" class="hide">
              <p>Ці файли cookie надають розширені функціональні можливості і персоналізацію, такі як відео і чат.
                Вони
                можуть бути встановлені нами або сторонніми постачальниками, чиї послуги ми додали на наші сторінки.
                Якщо ви не дозволите використання цих файлів cookie, деякі або всі ці функції можуть працювати
                неправильно.</p>
            </div>
          </div>
          <div class="item">
            <div class="visible">
              <div @click="drop4 = !drop4" class="name">
                <svg v-if="!drop4" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#2FDAFF"/>
                </svg>

                <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.5 13H5.5V11H19.5V13Z" fill="#2FDAFF"/>
                </svg>

                <p>Цільові cookies</p>
              </div>
              <div class="input">
                <input
                    type="checkbox"
                    name="ot-group-id-C0004"
                    id="ot-group-id-C0004"
                    aria-checked="false"
                    role="switch"
                    class="category-switch-handler"
                    data-optanongroupid="C0004"
                    aria-labelledby="ot-header-id-C0004"
                    v-model="targetCookies"
                />
                <label class="ot-switch" for="ot-group-id-C0004">
                  <span class="ot-switch-nob"></span>
                </label>
              </div>
            </div>
            <div v-if="drop4" class="hide">
              <p>Ці файли cookie встановлюються через наш сайт нашими рекламними партнерами. Вони можуть
                використовуватися цими компаніями для створення профілю ваших інтересів і показу вам релевантних
                оголошень на інших сайтах. Вони працюють, унікально ідентифікуючи ваш браузер і пристрій. Якщо ви не
                дозволите використання цих файлів cookie, ви не побачите нашу цільову рекламу на різних сайтах.</p>
            </div>
          </div>
        </div>

        <div class="btn-wrapper">
          <div class="btn color" @click="accept">Підтвердити вибір</div>
          <div class="btn base" @click="rejectAll">Відхилити все</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/style/styles";

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.input {
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .ot-switch .ot-switch-nob {
    background-color: #2FDAFF;
  }

  input:checked + .ot-switch .ot-switch-nob:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: white;
    box-shadow: 0px 5.25px 5.25px rgba(0, 0, 0, 0.20);

  }

  input:focus + .ot-switch .ot-switch-nob:before {
    outline: none;
  }

  .ot-switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 24px;
    margin-bottom: 0;
  }

  .ot-switch-nob {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #526577;
    border: none;
    transition: all 0.2s ease-in 0s;
    -moz-transition: all 0.2s ease-in 0s;
    -o-transition: all 0.2s ease-in 0s;
    -webkit-transition: all 0.2s ease-in 0s;
    border-radius: 46px;
  }

  .ot-switch-nob:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    bottom: 1px;
    background-color: #ADC0CD;
    -webkit-transition: 0.4s;
    border-radius: 100%;
    top: 3px;
    left: 4px;
    transition: 0.4s;
  }
}

.cokies {

  .stage1 {
    padding: 40px 32px 48px 32px;
    position: fixed;
    bottom: 100px;
    left: 84px;
    right: 84px;

    background: #10384F;

    @media screen and (max-width: 1024px) {
      left: 10px;
      right: 10px;
      padding: 20px;
    }

    @media screen and (max-width: 768px) {
      padding: 20px;

      bottom: 10px;
      left: 10px;
      right: 10px;
    }

    .double-block {
      display: flex;
      align-items: center;

      @media screen and (max-width: 940px) {
        flex-direction: column;
      }

      .text {

        h5 {
          color: white;
          font-family: $bold;
          font-size: 18px;
          line-height: 120%;
          margin-bottom: 12px;
        }

        p {
          font-size: 13px;
          color: white;
          font-family: $reg;
          line-height: 140%;

          a {
            color: #2FDAFF;
            font-family: $med;
            text-decoration: none;
          }
        }
      }

      .btn-wrapper {
        margin-left: 40px;

        @media screen and (max-width: 940px) {
          margin-left: 0;
          margin-top: 20px;
          width: 100%;
        }

        .btn {
          max-width: 288px;
          min-width: 288px;
          width: 100%;

          padding: 15px 30px;
          font-size: 14px;

          font-family: $bold;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          margin-bottom: 8px;
          cursor: pointer;

          @media screen and (max-width: 940px) {
            min-width: 1px;
            max-width: 100%;
            font-size: 12px;

          }

          &:last-child {
            margin-bottom: 0;
          }

          &.color {
            color: white;
            background: #F7931C;
            will-change: background-color;
            transition: background-color .2s ease;
            @media screen and (min-width: 1024px) {
              &:hover {
                background-color: #EA7F01;
              }
            }

            &:active {
              background-color: #DD7800;
            }
          }

          &.base {
            color: #2FDAFF;
            border: 1px solid #2FDAFF;

            will-change: background-color, color;
            transition: background-color .2s ease, color .2s ease;

            @media screen and (min-width: 1024px) {
              &:hover {
                background-color: #2FDAFF;
                color: white;
              }
            }

            &:active {
              background-color: #09BFE7;
              color: white;
            }
          }
        }
      }
    }
  }

  .stage2 {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    display: flex;
    justify-content: flex-end;

    background: rgba(0, 0, 0, 0.50);

    .block {
      height: 100vh;
      overflow-y: scroll;
      max-width: calc(410px + 48px);
      width: 100%;
      border-radius: 5px 0 0 5px;
      background: #10384F;
      box-shadow: -15px 0px 50px 0px rgba(37, 40, 52, 0.35);
      padding: 40px 24px;
      position: relative;

      display: flex;
      flex-direction: column;

      will-change: transform;

      animation: slide .5s;
      @media screen and (max-width: 768px) {
        padding: 32px 20px;
      }

      &::-webkit-scrollbar {
        width: 8px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #526577;
      }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }

      h5 {
        font-size: 20px;
        font-family: $bold;
        color: white;
        margin-bottom: 15px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      .reg-text {
        color: rgba(255, 255, 255, 0.80);
        font-family: $reg;
        font-size: 13px;
        line-height: 140%;
        margin-bottom: 15px;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }

      a {
        color: #2FDAFF;
        font-family: $med;
        text-decoration: none;
        font-size: 13px;
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }


    }

    .control {
      margin-top: 32px;
      margin-bottom: 45px;

      h4 {
        font-size: 18px;
        color: white;
        font-family: $bold;
        line-height: 140%;
        margin-bottom: 20px;
      }

      .item {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        .visible {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .name {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: white;
            font-family: $bold;
            line-height: 160%;

            svg {
              margin-right: 8px;
              display: block;
              min-width: 24px;
              width: 24px;
            }
          }

          .input {
            p {
              color: rgba(255, 255, 255, 0.40);
              text-align: right;
              font-size: 14px;
              font-family: $reg;
              line-height: 160%; /* 22.4px */
            }
          }
        }

        .hide {
          margin-top: 6px;

          p {
            color: rgba(255, 255, 255, 0.80);
            font-size: 12px;
            font-family: $reg;
            font-weight: 400;
            line-height: 120%; /* 14.4px */
          }
        }
      }
    }

    .btn-wrapper {
      margin-top: auto;
      margin-bottom: 0;

      .btn {
        width: 100%;

        padding: 15px 30px;
        font-size: 14px;

        font-family: $bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-bottom: 8px;
        cursor: pointer;

        @media screen and (max-width: 940px) {
          min-width: 1px;
          max-width: 100%;
          font-size: 12px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.color {
          color: white;
          background: #F7931C;

          will-change: background-color;
          transition: background-color .2s ease;

          @media screen and (min-width: 1024px) {
            &:hover {
              background-color: #EA7F01;
            }
          }

          &:active {
            background-color: #DD7800;
          }
        }

        &.base {
          color: #2FDAFF;
          border: 1px solid #2FDAFF;

          will-change: background-color, color;
          transition: background-color .2s ease, color .2s ease;

          @media screen and (min-width: 1024px) {
            &:hover {
              background-color: #2FDAFF;
              color: white;
            }
          }

          &:active {
            background-color: #09BFE7;
            color: white;
          }
        }
      }
    }
  }
}
</style>