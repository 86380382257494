import {createRouter, createWebHistory} from 'vue-router'
import home from '../views/home/home.vue'

const use = () => import('@/views/use.vue')
const policy = () => import('@/views/policy.vue')

const routes = [
    {path: '/', name: 'home', component: home},
    {path: '/use', component: use},
    {path: '/policy', component: policy}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), routes, scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
})

export default router
