<template>
  <div class="bottom-block">
    <div class="bottom-block__links">
      <router-link onclick="gtag('event', 'click_link', {'btn_name': 'Загальні умови використання'})" to="/use">Загальні умови використання</router-link>
      <router-link onclick="gtag('event', 'click_link', {'btn_name': 'Конфіденційність'})" to="/policy">Конфіденційність</router-link>
    </div>
    <div class="bottom-block__copy">Copyright © Bayer AG</div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/style/styles";

.bottom-block {
  display: flex;
  margin: auto 0 0 0;
  padding: 30px 84px;
  background: #10384F;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    padding: 30px 20px;
  }

  @media screen and (max-width: 768px) {
    padding: 25px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__links {
    a {
      text-decoration: none;
      transition: color .2s ease;
      will-change: color;
      font-size: 16px;
      font-family: $med;
      color: #2FDAFF;
      margin-right: 54px;

      &:active {
        color: white;
      }
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        text-align: center;
        margin-right: 0;

        &:first-child {
          margin-bottom: 15px;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      a {
        &:hover {
          color: white;
        }
      }
    }
  }

  &__copy {
    color: rgba(255, 255, 255, 0.60);
    font-family: $reg;
    font-size: 16px;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      margin-top: 32px;
    }
  }
}
</style>