import { createApp } from 'vue'
import "@babel/polyfill";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueHtml2Canvas from 'vue-html2canvas';



createApp(App).use(store).use(VueHtml2Canvas).use(router).mount('#app')
