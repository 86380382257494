import {createStore} from 'vuex'

export default createStore({
    state: {
        prePopup: true,
        cokiesPopup: true,
        cokiesPopupStage2: false
    },
    getters: {
        getPrePopup(state) {
            return state.prePopup
        },
        getCokiesPopup(state) {
            return state.cokiesPopup
        },
        getCokiesPopupStage2(state) {
            return state.cokiesPopupStage2
        }
    },
    mutations: {
        closePrePopup(state) {
            state.prePopup = false
            sessionStorage.setItem("pre-popup", "true")
        },
        closeCokiesPopup(state) {
            state.cokiesPopup = false
            sessionStorage.setItem("cokiesPopup", "true")
        },
        openCokiesPopup(state) {
            state.cokiesPopup = true
        },
        closeCokiesPopupStage2(state) {
            state.cokiesPopupStage2 = false
        },
        openCokiesPopupStage2(state) {
            state.cokiesPopupStage2 = true
        }
    },
    actions: {
        doPrePopup(context) {
            context.commit('closePrePopup')
        },
        doCloseCokiesPopup(context) {
            context.commit('closeCokiesPopup')
        },
        doOpenCokiesPopup(context) {
            context.commit('openCokiesPopup')
        },
        doCloseCokiesPopupStage2(context) {
            context.commit('closeCokiesPopupStage2')
        },
        doOpenCokiesPopupStage2(context) {
            context.commit('openCokiesPopupStage2')
        }
    },
    modules: {}
})
