<script>
import acardion from "@/views/home/components/acardion.vue";
import calc from "@/views/home/components/calc.vue";
import {mapActions} from "vuex";

export default {
  name: 'pre-popup',
  data: () => ({
    stage: 1
  }),
  methods: {
    ...mapActions([
      "doPrePopup"
    ]),
    reload() {
      location = "https://www.souu.org.ua/";
    }
  }
}
</script>

<template>
  <div class="pre-popup">

    <div class="popup popup-stage1" v-if="stage === 1">
      <div @click="reload()" class="close">
        <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19.5 7.31598L18.09 5.90598L12.5 11.496L6.91 5.90598L5.5 7.31598L11.09 12.906L5.5 18.496L6.91 19.906L12.5 14.316L18.09 19.906L19.5 18.496L13.91 12.906L19.5 7.31598Z"
              fill="#10384F" fill-opacity="0.4"/>
        </svg>
      </div>
      <h2>Увага!</h2>
      <p class="m-b">Ця інформація містить матеріали, які згідно з чинним законодавством можуть бути доступні лише
        медичним та
        фармацевтичним працівникам.</p>
      <p><b>Ви є медичним або фармацевтичним працівником?</b></p>
      <div class="btn-wrapper">
        <div @click="stage = 2" class="btn yes">Так</div>
        <div @click="reload()" class="btn no">Ні</div>
      </div>

    </div>

    <div class="popup popup-stage2" v-if="stage === 2">
      <div @click="doPrePopup()" class="close">
        <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19 6.91L17.59 5.5L12 11.09L6.41 5.5L5 6.91L10.59 12.5L5 18.09L6.41 19.5L12 13.91L17.59 19.5L19 18.09L13.41 12.5L19 6.91Z"
              fill="#10384F" fill-opacity="0.4"/>
        </svg>
      </div>
      <h3>Калькулятор часу подвоєння ПСА не є медичним виробом та не призначений для визначення та/або проведення
        діагностики захворювання у пацієнтів!</h3>
      <div class="double">
        <p>Натискаючи кнопку «Продовжити», я погоджуюся iз зазначеним вище.</p>
        <div class="btn-next" @click="doPrePopup()">Продовжити</div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
@import "@/assets/style/styles";

.pre-popup {
  background: rgba(0, 0, 0, 0.50);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;

  .popup {
    background: white;
    max-width: 587px;
    width: 100%;
    position: relative;

    &.popup-stage1 {
      padding: 50px 50px 64px 50px;

      @media screen and (max-width: 768px) {
        padding: 50px 20px 50px 20px;
      }

      h2 {
        color: #10384F;
        font-size: 40px;
        font-family: $bold;
        text-align: center;
        margin-bottom: 32px;

        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
          font-size: 30px;
        }
      }

      p {
        font-size: 18px;
        font-family: $reg;
        line-height: 140%;
        color: #10384F;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }

        &.m-b {
          margin-bottom: 12px;
        }

        b {
          font-family: $bold;
        }
      }

      .btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 32px;

        @media screen and (max-width: 768px) {
          margin-top: 20px;
        }

        .btn {
          padding: 15px 30px;
          width: 100%;

          display: flex;
          justify-content: center;
          align-items: center;

          cursor: pointer;
          max-width: 124px;
          @media screen and (max-width: 768px) {
            padding: 15px 30px;
            max-width: 145px;
          }

          &.yes {
            background-color: #F7931C;
            margin-right: 12px;
            border-radius: 5px;

            color: #FFF;
            font-size: 16px;

            font-family: $semi;

            will-change: background-color;
            transition: background-color .2s ease;

            @media screen and (min-width: 1024px) {
              &:hover {
                background-color: #EA7F01;
              }
            }

            &:active {
              background-color: #DD7800;
            }

            @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }

          &.no {
            border-radius: 5px;
            border: 1px solid #10384F;

            font-size: 16px;
            font-family: $semi;
            color: #10384F;
            background-color: white;

            will-change: background-color, color;
            transition: color .2s ease, background-color .2s ease;

            @media screen and (min-width: 1024px) {
              &:hover {
                background-color: #10384F;
                color: white;
              }
            }

            &:active {
              background-color: #0C293A;
              color: white;
            }

            @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
      }
    }

    &.popup-stage2 {
      padding: 50px 40px 64px 40px;

      @media screen and (max-width: 768px) {
        padding: 50px 20px 50px 20px;
      }

      h3 {
        color: #10384F;
        font-size: 24px;
        font-family: $bold;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      .double {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 36px;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        p {
          font-size: 18px;
          color: #10384F;
          font-family: $reg;
          margin-right: 20px;
          @media screen and (max-width: 768px) {
            font-size: 16px;
            margin-right: 0;
            margin-bottom: 20px;
          }
        }

        .btn-next {
          cursor: pointer;
          background-color: #F7931C;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 30px;
          border-radius: 5px;
          color: white;
          font-family: $semi;
          height: 50px;

          will-change: background-color;
          transition: background-color .2s ease;

          @media screen and (min-width: 1024px) {
            &:hover {
              background-color: #EA7F01;
            }
          }

          &:active {
            background-color: #DD7800;
          }

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }

    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;

      svg {
        width: 25px;
        display: block;
        height: auto;
      }
    }
  }
}
</style>