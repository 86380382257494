<template id="app">
  <div v-bind:class="[{ active: mobile === true }]">
    <prePopup v-if="getPrePopup"/>
    <topBlock/>
    <router-view/>
    <bottomBlock/>
    <cokies v-if="getCokiesPopup"/>
  </div>
</template>

<script>
import topBlock from '@/components/top-block.vue'
import bottomBlock from '@/components/bottom-block.vue'
import prePopup from '@/components/pre-popup.vue'
import cokies from '@/components/cokies.vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'app',
  components: {
    topBlock,
    bottomBlock,
    prePopup,
    cokies
  },
  data: () => ({
    mobile: false,
  }),
  computed: {
    ...mapGetters([
      "getPrePopup",
      "getCokiesPopup"
    ])
  },
  methods: {
    ...mapActions([
      "doPrePopup",
      "doCloseCokiesPopup"
    ])
  },
  beforeMount() {
    this.$nextTick(() => {
      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if (isAndroid || iOS === true) {
        this.mobile = true
      }
    })
    if (sessionStorage.getItem("pre-popup")) {
      this.doPrePopup()
    }
    if (localStorage.getItem("cookies")) {
      this.doCloseCokiesPopup()
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/style/styles";


#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    min-height: initial;
  }

  .active {
    * {
      cursor: none !important;
      outline: none !important;
    }
  }
}
</style>

