<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import * as events from "events";
import {jsPDF} from "jspdf";
import topBlock from '@/components/top-block.vue'

const doc = new jsPDF('p', 'px');


export default {
  name: 'calc',
  computed: {
    events() {
      return events
    }
  },
  components: {topBlock, VueDatePicker},

  beforeMount() {
    this.format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    }

    this.$nextTick(() => {
      let ua = navigator.userAgent.toLowerCase();
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if (iOS === true) {
        this.ios = true
      }
    })
  },

  data() {
    return {
      ios: false,
      createPdf: false,
      format: null,
      pca: [
        {
          id: 1,
          date: 0,
          level: "",
          error: {
            date: false,
            pca: false,
            errorText: false
          },
          isOpen: false
        },
        {
          id: 2,
          date: 0,
          level: "",
          isOpen: false,
          error: {
            date: false,
            pca: false,
            errorText: false
          },
        }
      ],
      result: false,
      timePCA: 0,
      speedPVA: 0,

      timePCAYear: 0,
      speedPVAYear: 0,

      disableBtn: true,
      output: null,
      clearBtn: false,
    }
  },

  methods: {
    handleDate(id) {
      if (new Date(this.pca.find(x => x.id === id).date) > new Date()) {
        this.pca.find(x => x.id === id).error.date = true
      } else {
        this.pca.find(x => x.id === id).error.date = false
      }
    },

    scrollToStart() {
      window.scrollTo(0, 0);
    },

    checkout() {
      let checker = arr => arr.every(v => v.error.date === false);

      if (checker(this.pca)) {
        window.scrollTo(0, 0);
        this.calc()
      }
    },

    addMorePCA() {
      this.pca.push(
          {
            id: this.pca.length + 1,
            date: 0,
            level: "",
            error: {
              date: false,
              pca: false,
              errorText: false
            },
            isOpen: false
          }
      )
    },

    clearAll() {
      window.scrollTo(0, 0);

      this.result = false
      this.pca = [
        {
          id: 1,
          date: 0,
          level: "",
          error: {
            date: false,
            pca: false,
            errorText: false
          },
          isOpen: false
        },
        {
          id: 2,
          date: 0,
          level: "",
          isOpen: false,
          error: {
            date: false,
            pca: false,
            errorText: false
          },
        }
      ]
    },

    calc() {
      let data = this.pca;
      let dateItem = 0
      let levelItem = 0;

      data.sort((a, b) => new Date(a.date) - new Date(b.date));

      const date1 = new Date(data[0].date);
      const date2 = new Date(data[data.length - 1].date);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil((diffTime / (1000 * 60 * 60 * 24)));

      let rowLevel1 = data[0].level
      let rowLevel2 = data[data.length - 1].level

      if (rowLevel1.includes(',')) {
        rowLevel1 = rowLevel1.replace(',', '.')
      }
      if (rowLevel2.includes(',')) {
        rowLevel2 = rowLevel2.replace(',', '.')
      }

      const level1 = Number(rowLevel1)
      const level2 = Number(rowLevel2)

      levelItem = Math.log(level2) - Math.log(level1);
      dateItem = (diffDays / 30.44) * Math.log(2)

      let timeCalc = (dateItem / levelItem)
      let speedCalc = ((level2 - level1) / (diffDays / 30.44))

      let timeCalcYear = ((dateItem / levelItem) * (1 / 12))
      let speedCalcYear = (((level2 - level1) / (diffDays / 365.25)))

      this.speedPVA = speedCalc.toFixed(2)
      this.timePCA = timeCalc.toFixed(2)

      this.timePCAYear = timeCalcYear.toFixed(2)
      this.speedPVAYear = speedCalcYear.toFixed(2)

      if (this.timePCA === 'Infinity' || this.timePCA === '-Infinity' || this.timePCA === 'NaN') {
        this.timePCA = 0
      }
      if (this.timePCAYear === 'Infinity' || this.timePCAYear === '-Infinity' || this.timePCAYear === 'NaN') {
        this.timePCAYear = 0
      }

      if (this.speedPVA === 'Infinity' || this.speedPVA === '-Infinity' || this.speedPVA === 'NaN') {
        this.speedPVA = 0
      }
      if (this.speedPVAYear === 'Infinity' || this.speedPVAYear === '-Infinity' || this.speedPVAYear === 'NaN') {
        this.speedPVAYear = 0
      }

      gtag('event', 'calc_result', {'resul_value': 'Час подвоєння ПСА: ' + this.timePCA + ", Швидкість наростання ПСА: " + this.speedPVA})

      this.result = true
    },

    deleteItem(id) {
      const objWithIdIndex = this.pca.findIndex((obj) => obj.id === id);

      if (objWithIdIndex > -1) {
        this.pca.splice(objWithIdIndex, 1);
      }
    },

    async saveImg() {

      const el = this.$refs.printMe;

      const options = {
        scale: 1.35,
        type: 'dataURL',
        width: 595,
        height: 842,
      }

      this.output = await this.$html2canvas(el, options);
      doc.addImage(this.output, "PNG", 0, 0);
      doc.save("result.pdf");

    },

    async openPdf() {


      const el = this.$refs.printMe;

      const options = {
        scale: 1.35,
        type: 'dataURL',
      }

      this.output = await this.$html2canvas(el, options);
      doc.addImage(this.output, "PNG", 0, 0);

      window.open(URL.createObjectURL(doc.output("blob")))

    },

    patter(item, id, event) {

      let text = ""

      this.pca.find(x => x.id === id).level = item.match(/^\d+\.?\d{0,2}/)[0];

      if (item > 10000) {
        text = item.toString().slice(0, -1)
        this.pca.find(x => x.id === id).level = text
      }
      if (item.toString().length >= 11) {
        text = item.toString().slice(0, -1)
        this.pca.find(x => x.id === id).level = text
      }
    },

    setInputFilter(textbox, inputFilter, errMsg, event) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout"].forEach(function (event) {
        textbox.addEventListener(event, function (e) {
          if (inputFilter(this.value)) {
            if (["keydown", "mousedown", "focusout"].indexOf(e.type) >= 0) {
              this.setCustomValidity("");
            }
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.setCustomValidity(errMsg);
            this.reportValidity();
            this.value = this.oldValue;
            console.log(this.oldValue)
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
          } else {
            this.value = "";
          }
        });
      });
    }
  },

  watch: {
    pca: {
      handler(val) {
        this.disableBtn = val.some((el) => {
          return el.date === 0 || el.level === "" || Number(el.level) === 0 || el.error.date
        })

        this.clearBtn = val.some((el) => {
          return el.date !== 0 || el.level !== ""
        })
      },
      deep: true
    },
  }
}
</script>

<template>
  <div class="calc">
    <div class="calc__pre-text">
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.9997 21.3333V14.6667M15.9997 2.66666C8.66634 2.66666 2.66634 8.66666 2.66634 16C2.66634 23.3333 8.66634 29.3333 15.9997 29.3333C23.333 29.3333 29.333 23.3333 29.333 16C29.333 8.66666 23.333 2.66666 15.9997 2.66666Z"
            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16.0068 10.6667H15.9948" stroke="white" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>

      <p>
        Цей інструмент може використовуватися для розрахунку часу подвоєння ПСА. Рішення про призначення тієї чи іншої
        терапії здійснюється виключно лікарем.
      </p>
    </div>

    <div v-bind:class="[{ errorText: item.error.pca }, { errorText: item.error.date }, { errorText: item.level === 0 }]"
         v-if="!result" class="calc__pca"
         v-for="(item, value) in pca"
         :key="item.id">

      <p class="name">ПСА тест №{{ value + 1 }}</p>

      <div class="calc__field">
        <VueDatePicker
            @open="item.isOpen = true;item.error.date = false;"
            @closed="item.isOpen = false"
            auto-apply
            v-model="item.date"
            :format="format"
            v-bind:class="[{ error: item.error.date }]"
            locale="ua" :enable-time-picker="false"
            @update:model-value="handleDate(item.id)"
            placeholder="ДД.ММ.РРРР">
          <template #input-icon>
            <p v-bind:class="[{ error: item.error.date }]" class="name-error">Виберіть минулу дату</p>
            <p v-bind:class="[{ active: item.isOpen }]" class="name-input">Дата</p>
            <svg v-bind:class="[{ active: item.isOpen}]" class="input-slot-image" width="24" height="24"
                 viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_522_1447)">
                <path
                    d="M9 11H7V13H9V11ZM13 11H11V13H13V11ZM17 11H15V13H17V11ZM19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z"
                    fill="#0171BB"/>
              </g>
              <defs>
                <clipPath id="clip0_522_1447">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </template>

        </VueDatePicker>
        <div class="calc__level" v-bind:class="[{ error: item.error.pca }, { error: item.level === 0 }]">
          <input min="0" @input="patter(item.level, item.id, $event)" v-model="item.level" type="text" id="level"
                 name="level"
                 placeholder="0.01"/>
          <label for="level" class="color-label">Рівень ПСА,нг/мл</label>
        </div>

        <div class="calc__close" v-if="value >= 2" @click="deleteItem(item.id)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="#2FDAFF"/>
          </svg>
        </div>
      </div>
    </div>

    <div v-if="!result" class="calc__button-wrapper">

      <div @click="addMorePCA()" class="calc__add-more">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_143_4453)">
            <circle cx="12" cy="12" r="12" fill="#2FDAFF"/>
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#0171BB"/>
          </g>
          <defs>
            <clipPath id="clip0_143_4453">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        <p>Додати ще один ПСА тест</p>
      </div>

      <div class="calc__control">
        <div v-if="clearBtn" @click="clearAll()" class="calc__clear">
          Очистити
        </div>
        <div v-bind:class="[{ disable: disableBtn }]" @click="checkout()" class="calc__checkout">
          Розрахувати
        </div>
      </div>
    </div>

    <div v-show="result" class="result">
      <div class="result__head">Ваші результати</div>
      <div class="result__body">
        <div class="result__line">
          <p class="result__line--empty"></p>
          <p class="result__line--fz20">Місяць</p>
          <p class="result__line--fz20">Рік</p>
        </div>
        <div class="result__line">
          <p class="result__line--fz16">Час подвоєння ПСА</p>
          <div class="result__line--fz40"><p>{{ timePCA }}</p></div>
          <div class="result__line--fz40"><p>{{ timePCAYear }}</p></div>
        </div>
        <div class="result__line">
          <p class="result__line--fz16">Швидкість наростання ПСА</p>
          <div class="result__line--fz40"><p>{{ speedPVA }}</p> <span>нг/мл</span></div>
          <div class="result__line--fz40"><p>{{ speedPVAYear }}</p> <span>нг/мл</span></div>
        </div>
        <div class="result__item--button">
          <div @click="saveImg()" class="result__btn"
               onclick="gtag('event', 'click_btn', {'btn_name': 'Завантажити результат'})">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M3 19H21V21H3V19ZM13 13.172L19.071 7.1L20.485 8.514L12 17L3.515 8.515L4.929 7.1L11 13.17V2H13V13.172Z"
                  fill="#2FDAFF"/>
            </svg>
            <p>Завантажити результат</p>
          </div>
          <a onclick="gtag('event', 'click_btn', {'btn_name': 'Надіслати на email'})"
             :href="`mailto:@?subject=Результати тесту \'Час подвоєння ПСА\'&body=Шановний(а) ПІБ!%0D%0A%0D%0AПропонуємо Вам ознайомитись з результатами тесту Час подвоєння ПСА, що був проведений ДАТА.%0D%0AЧас подвоєння ПСА ${timePCA} міс., ${timePCAYear} рік.%0D%0AШвидкість наростання ПСА ${speedPVA} нг/мл/міс, ${speedPVAYear} нг/мл/рік%0D%0AЯкщо у вас виникли додаткові запитання, будь ласка, зателефонуйте за номером +380ХХХХХХХХ, щоб узгодити час та дату консультації.%0D%0A%0D%0AЗ повагою,%0D%0AПІБ лікаря`"
             class="result__btn">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                  fill="#2FDAFF"/>
            </svg>

            <p>Надіслати на email</p>
          </a>
          <div class="result__btn" @click="openPdf()"
               onclick="gtag('event', 'click_btn', {'btn_name': 'Роздрукувати'})">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7 17H17V22H7V17ZM19 20V15H5V20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V9C2 8.73478 2.10536 8.48043 2.29289 8.29289C2.48043 8.10536 2.73478 8 3 8H21C21.2652 8 21.5196 8.10536 21.7071 8.29289C21.8946 8.48043 22 8.73478 22 9V19C22 19.2652 21.8946 19.5196 21.7071 19.7071C21.5196 19.8946 21.2652 20 21 20H19ZM5 10V12H8V10H5ZM7 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V6H6V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2Z"
                  fill="#2FDAFF"/>
            </svg>
            <p>Роздрукувати</p>
          </div>
        </div>

      </div>
      <div class="result__end-btn">

        <div @click="result = false; scrollToStart();" class="result__clear"
             onclick="gtag('event', 'click_btn', {'btn_name': 'Редагувати поточні дані'})">
          Редагувати поточні дані
        </div>
        <div @click="clearAll()" class="result__checkout"
             onclick="gtag('event', 'click_btn', {'btn_name': 'Новий розрахунок'})">
          Новий розрахунок
        </div>
      </div>
    </div>

    <div class="result-pdf">
      <div class="content" ref="printMe" v-bind:class="[{ iosActive: ios }]">

        <div class="top-block">
          <h1 class='top-block__text'>Калькулятор часу подвоєння ПСА</h1>
        </div>
        <h4>
          Ваші результати
        </h4>
        <p>Час подвоєння ПСА {{ timePCA }} міс., {{ timePCAYear }} рік.</p>
        <p>Швидкість наростання ПСА {{ speedPVA }} нг/мл/міс, {{ speedPVAYear }} нг/мл/рік</p>
        <p class="sep">Результати основані на реальних даних</p>
        <table>
          <thead>
          <tr>
            <td>Дата</td>
            <td>ПСА, нг/мл</td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="pcaList in pca">
            <td>{{ new Date(pcaList.date).getUTCDate() }}.{{
                new Date(pcaList.date).getUTCMonth() + 1
              }}.{{ new Date(pcaList.date).getUTCFullYear() }}
            </td>
            <td>{{ pcaList.level }}</td>
          </tr>
          </tbody>
        </table>
        <p class="more-text">
          <b>Час подвоєння ПСА є важливим маркером швидкості зростання ПСА,</b> який корелює з агресивністю раку
          простати.
          Зокрема, коротший час подвоєння ПСА корелює з більш агресивним процесом.
          <br>
          <br>
          Швидкість збільшення значень ПСА зазвичай описується як «кінетика ПСА». <b>Цей інструмент можна
          використовувати
          для розрахунку швидкості підвищення ПСА,</b> вираженої як швидкість у <span>нг/мл/міс</span>, часу подвоєння
          ПСА в місяцях, яку
          можна розглядати як прогноз: наприклад, можна очікувати, що у пацієнта з поточним рівнем ПСА
          <span>2 нг/мл</span> та
          швидкістю ПСА <span>0,05 нг/мл/міс</span> буде ПСА <span>2,6 нг/мл</span> через 12 місяців
          (<span>2 нг/мл</span>+(<span>0,05нг/мл/міс</span> *12 міс = <span>2.6 нг/мл</span>).
          <br>
          <br>
          <b>Час подвоєння ПСА — це кількість місяців, яка буде потрібна, щоб ПСА збільшився вдвічі.</b> Таким чином,
          час
          подвоєння ПСА у нашого пацієнта становитиме 40 місяців. У пацієнтів із відносно постійним ПСА, наприклад, зі
          зміною рівня ПСА від 6 до <span>6,1 нг/мл</span> протягом року, час подвоєння стає досить нестабільним, і
          таких пацієнтів
          зазвичай відносять до категорії «час подвоєння > 10 років» або аналогічного.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/style/styles";

.calc {
  max-width: calc(791px + 40px);
  width: 100%;
  padding: 0 20px;
  margin: 48px auto 0 auto;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
    margin: 30px auto 0 auto;

  }

  .error {
    ::v-deep .dp__input {
      border-color: #F00 !important;
    }

    ::v-deep .dp__input_icon {
      .name-input {
        color: #F00 !important;
      }


      svg {
        path {
          fill: #F00;
        }
      }
    }

    &.calc__level {
      label {
        color: #F00 !important;
      }

      input {
        border-color: #F00 !important;
      }
    }
  }

  ::v-deep .dp {
    &__overlay_cell_active {
      background: #F7931C;
    }

    &__cell_inner {
      font-size: 16px;
      font-weight: 400;

      color: #10384F;
    }

    &__btn {
      color: #F7931C;
      font-size: 16px;
      font-weight: bold;
    }

    &__cell_offset {
      pointer-events: none !important;
      visibility: hidden;
    }

    &__calendar_header_item {
      color: rgba(16, 56, 79, 0.40);
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 500;
    }

    &__calendar_header_separator {
      display: none;
    }

    &__active_date {
      color: white !important;
      background: #F7931C;
      border-radius: 50%;
    }

    &__inner {
      &_nav {
        svg {
          fill: #F7931C;
          stroke: #F7931C;
        }
      }
    }

    &__today {
      border: none !important;
      color: #F7931C;
      font-weight: bold;
    }

    &__date {

      &_hover {
        &:hover {
          color: #F7931C;
          background: white;
        }
      }
    }

    &__outer_menu_wrap {
      width: 100%;
      left: 0 !important;
    }

    &__clear_icon {
      display: none !important;
    }

    &__main {
      max-width: 273px;
      width: 100%;
      margin-left: 20px;
      @media screen and (max-width: 768px) {
        margin-left: 0;
        max-width: 50%;
      }
    }

    &__input {
      border-radius: 5px;

      padding: 18px 10px 8px 10px;
      font-family: $reg;
      font-size: 16px;
      color: black;
      line-height: normal;
      border: 2px solid white;
      transition: border-color .2s ease;
      will-change: border-color;

      &::placeholder {
        font-family: $reg;
        font-size: 16px;
        color: var(--black, #000);
        opacity: 0.3;
      }

      &_focus {
        border-color: #F7931C;
      }

      &_icon {
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        height: 100%;

        .input-slot-image {
          position: absolute;
          right: 9px;
          top: 0;
          bottom: 0;
          width: 24px;
          height: auto;
          display: block;
          margin: auto 0;

          path {
            transition: fill .2s ease;
            will-change: fill;
          }

          &.active {
            path {
              fill: #F7931C;
            }
          }
        }

        .name-error {
          display: none;
          position: absolute;
          bottom: -20px;
          left: 0;
          color: #FFF;
          font-family: $med;
          font-size: 12px;

          &.error {
            display: block;
          }
        }

        .name-input {
          position: absolute;
          left: 12px;
          top: 4px;

          color: var(--bg-1, #0171BB);
          font-family: $med;
          font-size: 12px;
          transition: color .2s ease;
          will-change: color;

          &.active {
            color: #F7931C;
          }
        }
      }
    }

    &__arrow_top {
      display: none;
    }
  }

  &__level {
    margin-left: 10px;
    max-width: 273px;
    width: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
      max-width: 50%;
    }

    &:before {
      content: 'Повинен бути >0';
      position: absolute;
      bottom: -20px;
      left: 0;

      color: #FFF;
      font-family: $med;
      font-size: 12px;

      //display: none;
    }

    &.error {
      &:before {
        display: block;
      }
    }

    input:focus ~ .color-label {
      color: #F7931C;
    }

    .color-label {
      position: absolute;
      left: 12px;
      top: 4px;

      color: #0171BB;
      font-family: $med;
      font-size: 12px;

      transition: color .2s ease;
      will-change: color;
      pointer-events: none;
    }

    input {
      width: 100%;
      padding: 18px 10px 8px 10px;
      color: black;
      border: 2px solid white;
      border-radius: 5px;
      font-size: 16px;
      font-family: $reg;
      transition: border-color .2s ease;
      will-change: border-color;


      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        border-color: #F7931C;
        outline: none;
      }

      &::placeholder {
        font-family: $reg;
        font-size: 16px;
        color: var(--black, #000);
        opacity: 0.3;
      }
    }
  }

  &__pca {
    margin-bottom: 4px;
    border-radius: 5px;
    background: #2096E3;
    display: flex;
    align-items: center;
    padding: 16px 20px 31px 20px;
    position: relative;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 10px 31px 10px;
    }

    .name {
      color: var(--ffffff, #FFF);
      font-family: $reg;
      font-size: 16px;
      font-weight: 700;
      display: inline-block;
      min-width: 130px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
        font-size: 14px;
        margin-bottom: 13px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__pre-text {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    @media screen and (max-width: 768px) {
      align-items: flex-start;
      margin-bottom: 24px;
    }

    svg {
      display: block;
      width: 32px;
      min-width: 32px;
      height: auto;
      margin-right: 10px;
      @media screen and (max-width: 768px) {
        width: 24px;
        min-width: 24px;
      }
    }

    p {
      color: #FFF;

      font-family: $reg;
      font-size: 16px;
      line-height: 140%;
    }

  }

  &__button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    @media screen and (max-width: 630px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__add-more {
    cursor: pointer;
    display: flex;
    align-items: center;

    @media screen and (min-width: 1023px) {

      &:hover {
        p {
          color: white;
        }

        svg {
          circle {
            fill: white;
          }
        }
      }
    }

    &:active {
      p {
        color: white;
      }

      svg {
        circle {
          fill: white;
        }
      }
    }

    svg {
      display: block;
      min-width: 24px;
      width: 24px;
      height: auto;
      margin-right: 8px;

      circle {
        transition: fill .2s ease;
        will-change: fill;
      }
    }

    p {
      color: var(--links, #2FDAFF);
      font-family: $semi;
      font-size: 16px;
      transition: color .2s ease;
      will-change: color;
    }
  }

  &__control {
    display: flex;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-top: 32px;
    }
  }

  &__clear {
    cursor: pointer;

    border-radius: 5px;
    border: 1px solid var(--links, #2FDAFF);

    font-size: 16px;
    font-family: $semi;
    color: #2FDAFF;
    padding: 15px 30px;

    will-change: background-color, color;
    transition: color .2s ease, background-color .2s ease;

    @media screen and (min-width: 1024px) {
      &:hover {
        background-color: #2FDAFF;
        color: white;
      }
    }

    &:active {
      background-color: #09BFE7;
      color: white;
    }

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }

    @media screen and (max-width: 630px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__checkout {
    cursor: pointer;
    margin-left: 8px;
    font-size: 16px;
    font-family: $semi;
    padding: 15px 30px;
    border-radius: 5px;
    color: white;
    background: #F7931C;
    border: 1px solid #F7931C;

    will-change: background-color;
    transition: background-color .2s ease;

    &.disable {
      background: #81B1DD;
      border: 1px solid #81B1DD;
      color: rgba(255, 255, 255, 0.4);
      pointer-events: none;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        background-color: #EA7F01;
      }
    }

    @media screen and (max-width: 630px) {
      width: 100%;
      margin-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }

    &:active {
      background-color: #DD7800;
    }
  }

  &__close {
    width: 24px;
    height: auto;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0 auto 0;
    display: flex;
    align-items: center;

    path {
      transition: fill .2s ease;
      will-change: fill;
    }

    @media screen and (min-width: 1023px) {
      &:hover {
        path {
          fill: white;
        }
      }
    }

    &:active {
      path {
        fill: white;
      }
    }

    @media screen and (max-width: 768px) {
      right: 10px;
      top: 8px;
      bottom: initial;
      margin: 0;
    }
  }

  &__field {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 40px;
    @media screen and (max-width: 768px) {
      padding-right: 0;
    }
    @media screen and (max-width: 380px) {
      //flex-direction: column;
    }
  }

  .result {
    width: 100%;

    &__end-btn {
      display: flex;
      margin-top: 20px;
      justify-content: flex-end;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    &__clear {
      cursor: pointer;

      border-radius: 5px;
      border: 1px solid var(--links, #2FDAFF);

      font-size: 16px;
      font-family: $semi;
      color: #2FDAFF;
      padding: 15px 30px;

      will-change: background-color, color;
      transition: color .2s ease, background-color .2s ease;

      @media screen and (min-width: 1024px) {
        &:hover {
          background-color: #2FDAFF;
          color: white;
        }
      }

      &:active {
        background-color: #09BFE7;
        color: white;
      }

      @media screen and (max-width: 768px) {
        font-size: 14px;
        display: flex;
        justify-content: center;
      }
    }

    &__checkout {
      cursor: pointer;
      margin-left: 8px;
      font-size: 16px;
      font-family: $semi;
      padding: 15px 30px;
      border-radius: 5px;
      color: white;
      background: #F7931C;
      will-change: background-color;
      transition: background-color .2s ease;
      @media screen and (min-width: 1024px) {
        &:hover {
          background-color: #EA7F01;
        }
      }

      @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-top: 8px;
        display: flex;
        justify-content: center;
        font-size: 14px;
      }

      &:active {
        background-color: #DD7800;
      }
    }

    &__head {
      background: #2096E3;
      padding: 16px 20px;
      display: flex;
      justify-content: center;
      border-radius: 5px 5px 0 0;

      color: var(--ffffff, #FFF);

      font-family: $semi;
      font-size: 26px;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    &__body {
      background: var(--bg-2, #10384F);
      border-radius: 0 0 5px 5px;
      padding-top: 30px;
      @media screen and (max-width: 768px) {
        padding-top: 12px;
      }
    }

    &__item {
      padding: 30px 20px;

      display: flex;
      justify-content: center;
      background: #10384F;

      @media screen and (max-width: 768px) {
        padding: 20px;
      }

      &--button {
        padding: 16px 40px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 768px) {
          align-items: flex-start;
          padding: 16px 16px;
        }
      }
    }

    &__line {
      display: flex;
      width: calc(100% - 80px);
      align-items: center;
      padding: 12px 0;
      margin: 0 40px;
      border-bottom: 1px solid #406072;
      @media screen and (max-width: 768px) {
        margin: 0 16px;
        padding: 8px 0;
        width: calc(100% - 32px);
      }

      &--empty {
        max-width: 280px;
        width: 100%;
      }

      &--fz20 {
        font-size: 20px;
        color: white;
        font-family: $bold;
        max-width: 210px;
        width: 100%;
        padding-left: 5px;

        @media screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      &--fz16 {
        font-size: 16px;
        font-family: $semi;
        color: white;
        max-width: 280px;
        width: 100%;
        padding-right: 5px;
        @media screen and (max-width: 768px) {
          font-size: 13px;
          font-family: $reg;
        }
      }

      &--fz40 {

        max-width: 210px;
        width: 100%;
        padding-left: 5px;


        p {
          line-height: 100%;

          font-size: 40px;
          color: #FFF;
          font-family: $bold;
          @media screen and (max-width: 768px) {
            font-size: 24px;
          }
          @media screen and (max-width: 400px) {
            font-size: 18px;
          }
        }

        span {
          font-size: 20px;
          color: #FFF;
          font-family: $bold;
          white-space: nowrap;

          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;

      cursor: pointer;
      text-decoration: none;

      @media screen and (min-width: 1023px) {
        &:hover {

          svg {
            path {
              fill: white;
            }
          }

          p {
            color: white;
          }
        }
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0 10px;
      }

      svg {
        width: 24px;
        height: auto;
        display: block;
        margin-right: 5px;

        @media screen and (max-width: 768px) {
          margin-right: 0;
        }

        path {
          transition: fill .2s ease;
          will-change: fill;
        }
      }

      p {
        color: var(--links, #2FDAFF);
        font-family: $med;
        font-size: 16px;

        transition: color .2s ease;
        will-change: color;

        @media screen and (max-width: 768px) {
          font-size: 12px;
          text-align: center;
        }
      }

    }

    &__pca-result {
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }

      &.speed {
        margin-right: 55px;
        @media screen and (max-width: 768px) {
          margin-right: 40px;
          min-width: 102px;
        }
      }

      &--small {
        color: #FFF;
        font-family: $reg;
        font-size: 12px;
        margin-bottom: 5px;

        br {
          display: none;
        }

        @media screen and (max-width: 768px) {
          font-size: 13px;
          br {
            display: block;
          }
        }
      }

      &--big {
        .first {
          color: #FFF;
          font-family: $bold;
          font-size: 40px;
          @media screen and (max-width: 768px) {
            font-size: 28px;
          }
        }

        .second {
          font-size: 20px;
          color: #FFF;
          font-family: $bold;
          white-space: nowrap;
          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .result-pdf {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;

    background: white;

    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-500%);
    pointer-events: none;
    z-index: 100;

    .content {
      background: white;
      width: 595px;
      max-width: 595px;
      min-width: 595px;
      min-height: 842px;
      max-height: 2000px;
      height: 100%;
      top: 0;

      &.iosActive {
        zoom: 1;

        h4 {
          font-size: 10px !important;
        }

        p {
          font-size: 8px !important;
        }
      }

      h4 {
        font-family: $bold;
        font-weight: bold;
        margin-top: 25px;
        margin-bottom: 10px;
        padding: 0 20px;
        font-size: 16px;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }

      p {
        font-family: $reg;
        font-size: 14px;
        margin-bottom: 10px;
        padding: 0 20px;

        &.more-text {
          margin-top: 20px;
          @media screen and (max-width: 768px) {
            font-size: 10px;
          }
        }

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }

        b {
          font-family: $bold;

        }

        span {
          white-space: nowrap;
        }

        &.sep {
          margin-top: 15px;
        }

      }

      table {
        border-collapse: collapse;
        margin-left: 20px;
        font-family: $reg;

        td {
          font-size: 14px;
          border: 1px solid black;
          padding: 5px 10px;

          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      .top-block {
        background: #10384F;
        position: relative;

        display: flex;
        align-items: center;
        padding: 26px 40px;

        width: 100%;

        @media screen and (max-width: 768px) {
          padding: 18px 20px;
        }

        &__text {
          font-size: 20px;
          font-family: $semi;
          color: white;

          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          text-align: center;

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>