<template>
  <div class="home">
    <calc></calc>
    <acardion></acardion>
    <div class="promo">PP-UN-ONC-UA-0001-1_19 September 2023</div>
  </div>
</template>

<script>
// @ is an alias to /src
import acardion from "@/views/home/components/acardion.vue"
import calc from "@/views/home/components/calc.vue"

export default {
  name: 'home',
  components: {
    acardion,
    calc
  }
}
</script>

<style lang="scss">
@import "@/assets/style/styles";

.home {
  background: #0171BB;
  min-height: calc(100vh - 180px);
  display: flex;

  flex-direction: column;

  @media screen and (max-width: 768px){
    min-height: calc(100vh - 232px);
  }

  .promo {
    margin-top: auto;
    margin-bottom: 27px;
    padding: 0 84px;

    color: rgba(255, 255, 255, 0.40);
    font-size: 12px;
    font-family: $med;

    @media screen and (max-width: 1024px) {
      margin-bottom: 10px;
      padding: 0 20px;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}
</style>
